import { Component, OnInit } from '@angular/core';
export interface LatestStories {
  Header: string;
  Content: string;
}
@Component({
  selector: 'app-latest-stories',
  templateUrl: './latest-stories.component.html',
  styleUrls: ['./latest-stories.component.css']
})
export class LatestStoriesComponent implements OnInit {

  constructor() { }
  LatestStories : LatestStories[] = [];

  ngOnInit(): void {
    this.LatestStories = [{ Header : "Teaching In Digital Classroom", Content :"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin"},
    { Header : "Teaching In Digital Classroom", Content :"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin"},
    { Header : "Teaching In Digital Classroom", Content :"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin"},
    { Header : "Teaching In Digital Classroom", Content :"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin"}]
  }

}
