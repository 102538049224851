<div class="row new_article_wrap">
    <div *ngIf="TabVersion" class="col-5" style="text-align: center;">
        <img [src]="EmailImg" class="email_img"/>
    </div>
    <div *ngIf="TabVersion" class="row col-7">
        <label class="new_article_everyday">New Article Everyday</label>
        <mat-form-field class="ywea-form-wrap" appearance="outline">
            <mat-label style="color: black;">Your work email address</mat-label>
            <input matInput>
        </mat-form-field>
        <button style="width: 110px;height: 50px;background-color:#18191E;color: white;margin-top:5px;border : none">Subscribe</button>
    </div>
    <div *ngIf="mobileVersion" style="text-align: center;" class="col-12">
        <label class="new_article_everyday" >New Article Everyday</label>
        <mat-form-field class="ywea-form-wrap" appearance="outline">
            <mat-label style="color: black;">Your work email address</mat-label>
            <input matInput>
        </mat-form-field>
        <button style="width: 110px;height: 50px;background-color:#18191E;color: white;margin-top:0px;border : none">Subscribe</button>
    </div>
</div>