<div class="row" style="margin: 0px;padding : 0px;background-color: #F8FBFF;">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-10">
        <label class="header_text" style="margin-top: 60px;">We Provide Training for Individuals.</label>
        <label class="header_text" style="margin-top: 10px;">Groups & corporates</label>
        <div class="row" style="margin-top: 20px;margin-bottom: 50px;">
            <div class="col-lg-4" style="margin-top: 20px;margin-bottom: 20px;">
                <div class="course_card">
                    <div class="width : 100%">
                        <img src="../../../assets/images/WeProvideTraining.png" style="width: 70px;height: 70px;">
                    </div>
                    <label class="course_name">Angular</label>
                    <label class="course_content">Must explain to you how this denouncing praisig pain was give you explain to you how...</label>
                </div>
            </div>
            <div class="col-lg-4" style="margin-top: 20px;margin-bottom: 20px;">
                <div class="course_card">
                    <div class="width : 100%">
                        <img src="../../../assets/images/WeProvideTraining.png" style="width: 70px;height: 70px;">
                    </div>
                    <label class="course_name">Python</label>
                    <label class="course_content">Must explain to you how this denouncing praisig pain was give you explain to you how...</label>
                </div>
            </div>
            <div class="col-lg-4" style="margin-top: 20px;margin-bottom: 20px;">
                <div class="course_card">
                    <div class="width : 100%">
                        <img src="../../../assets/images/WeProvideTraining.png" style="width: 70px;height: 70px;">
                    </div>
                    <label class="course_name">Experience</label>
                    <label class="course_content">Must explain to you how this denouncing praisig pain was give you explain to you how...</label>
                </div>
            </div>
        </div>
    </div>
</div>