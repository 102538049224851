<div *ngIf="screenWidth > 992" class="row" style="margin: 0px;padding: 0px;background-color: #FFFFFF;">
    <div class="col-lg-6 verticalalign_parent" style="height: 440px;">
        <div class="verticalalign_child">
            <label style="width: 100%;margin-left: 15%;font-size: 40px; font-weight: bold; color: black;">Get Trained</label>
            <label style="width: 100%;margin-left: 15%;font-size: 15px; color: black;margin-top: 20px;">With our super powers we have reached this</label>
        </div>
    </div>
    <div class="col-lg-6">
        <mat-tab-group class="remove-border-bottom" style="margin: 30px 0px 0px 0px;padding: 0px;" mat-align-tabs="start">
            <mat-tab label="Frontend"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Backend"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="DevOps"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Cloud"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Certifications"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
        </mat-tab-group>
    </div>
</div>
<div *ngIf="screenWidth < 993" class="row" style="margin: 0px;padding: 0px;">
    <div class="col-lg-12" style="text-align: center;">
        <label style="width: 100%;text-align: center;font-size: 40px; font-weight: bold; color: black;margin-top: 50px;text-align: center;">Get Trained</label>
        <label style="width: 100%;text-align: center;font-size: 15px; color: black;margin-top: 20px;">With our super powers we have reached this</label>
        <mat-tab-group class="remove-border-bottom" style="margin: 30px 0px 50px 0px;padding: 0px;" mat-align-tabs="center">
            <mat-tab label="Frontend"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Backend"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="DevOps"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Cloud"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
            <mat-tab label="Certifications"><img src="../../assets/images/CodingPlatforms.jpg" style="margin-top: 15px;"></mat-tab>
        </mat-tab-group>
    </div>
</div>