<footer class="row" style="margin: 0px;padding : 0px">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-5" style="margin-top: 60px;">
        <label class="footerheader">FOLLOW US</label>
        <img src="../../assets/images/facebook.png" style="margin-top: 15px;" class="footericons">
        <img src="../../assets/images/linkedin.png" style="margin-top: 15px;" class="footericons">
        <img src="../../assets/images/twitter.png" style="margin-top: 15px;" class="footericons">
    </div>
    <div class="col-lg-5" style="margin-top: 60px;">
        <label class="footerheader">FOLLOW US</label>
        <label class="email_text">hello@fapsterapp.com</label>
    </div>
    <div class="col-lg-1">
    </div>
    <div class="col-lg-1">
    </div>
    <div class="col-lg-5">
        <label class="footerheader" style="margin-top: 40px;margin-bottom: 20px;">INFORMATION</label>
        <div class="row">
            <li class="col-sm-4 list_texts">About Bachina Labs</li>
            <li class="col-sm-4 list_texts" style="text-decoration: underline;">Onhovered / Active</li>
            <li class="col-sm-4 list_texts">We are hiring!</li>
            <li class="col-sm-4 list_texts">Get in Touch</li>
            <li class="col-sm-4 list_texts">Privacy Policy</li>
            <li class="col-sm-4 list_texts">Resources</li>
            <li class="col-sm-4 list_texts">Things We Like</li>
            <li class="col-sm-4 list_texts">Terms of Service</li>
        </div>
    </div>
    <div class="col-lg-5">
        <label class="footerheader" style="margin-top: 40px;margin-bottom: 20px;">KEEP IN TOUCH</label>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field class="my-form-wrap" appearance="outline">
                    <mat-label style="font-weight: bold;font-size: 15px;color: #293133;padding-left: 15px;">Your name</mat-label>
                    <input style="font-weight: bold" matInput>
                    <mat-icon matSuffix class="material-icons-outlined" style="color: #ADADAD;">person</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field class="my-form-wrap" appearance="outline">
                    <mat-label style="font-weight: bold;font-size: 15px;color: #293133;padding-left: 15px;">E-mail</mat-label>
                    <input style="font-weight: bold" matInput>
                    <mat-icon matSuffix class="material-icons-outlined" style="color: #ADADAD;">mail</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-8">
                <mat-form-field class="my-form-wrap" appearance="outline">
                    <mat-label style="font-weight: bold;font-size: 20px;color: #E4440E;padding-left: 15px;">Leave your message</mat-label>
                    <textarea rows="3" style="color: gray;" matInput></textarea>
                    <mat-icon matSuffix class="material-icons-outlined" style="color: #E4440E;">rate_review</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <button mat-raised-button class="footer_button">SEND</button>
            </div>
        </div>
    </div>
</footer>