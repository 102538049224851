<div class="main_wrap">
  <mat-toolbar [ngStyle]="{'background-color': ChangeBackground == true ? '#FFFFFF' : '#D54918','position' : ChangeBackground == true ? 'fixed' : 'relative'}" style="z-index: 999;" class="mat-elevation-z5">
      <mat-toolbar-row>
          <button style="margin-left: 4%;" *ngIf="SideNaveDisplay" mat-icon-button (click)="sidenav.toggle()" matTooltip="Menu">
              <mat-icon [ngStyle]="{'color': ChangeBackground == true ? '#000000' : '#FFFFFF'}" aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <img [ngStyle]="{'margin-left': SideNaveDisplay == true ? '15px' : '10%'}" [src]="BachinaLabs" style="height: 30px;">
          <span class="spacer"></span>
          <div *ngIf="ToolbarDisplay" class="row" style="margin: 0px;padding: 0px;">
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}" routerLinkActive="active-list-item" [routerLink]="['Home']">Home</li>
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}" routerLinkActive="active-list-item" [routerLink]="['About']">About</li>
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}">Chapters</li>
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}">Reviews</li>
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}">Author</li>
              <li class="col-2 list_texts_toolbar" [ngStyle]="{'color' : ChangeBackground == true ? '#000000' : '#FFFFFF'}">Contact</li>
          </div>
          <div style="margin-right: 10%;">
          </div>
      </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav style="background-color: #ffffff;padding-top: 56px; overflow-x: hidden;position: fixed;"  [opened]="screenWidth < 950" mode = "over" class="sidenav mat-elevation-z3">
          <mat-nav-list>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;" routerLinkActive="active-list-item-sidenav" [routerLink]="['Home']">Home</mat-list-item>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;" routerLinkActive="active-list-item-sidenav" [routerLink]="['About']">About</mat-list-item>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;">Chapters</mat-list-item>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;">Reviews</mat-list-item>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;">Author</mat-list-item>
              <mat-list-item (click)= "sidenav.close()" style="letter-spacing: 1px;">Contact</mat-list-item>
          </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
          <router-outlet></router-outlet>
          <!-- <div class="row" style="margin: 0px;padding : 0px">
                <app-banner style="width: 100%;"></app-banner>
              <div class="new_article_section">
                  <app-subscribe-for-stories></app-subscribe-for-stories>
              </div>
          </div>
          <app-latest-stories></app-latest-stories>
          <app-we-provide-training></app-we-provide-training>
          <app-get-trained></app-get-trained>
          <app-ideas-into-projects></app-ideas-into-projects>
          <app-follow-us></app-follow-us>
          <app-main-footer></app-main-footer> -->
          <!-- <router-outlet></router-outlet> -->
      </mat-sidenav-content>
  </mat-sidenav-container>
</div>