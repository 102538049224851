import { Component, HostListener,ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Bachina';
  BachinaLabs : string = "assets/images/BachinaLabsWhite.png";
  ChangeBackground : boolean = false;
  screenHeight;screenWidth;
  ToolbarDisplay : boolean;
  SideNaveDisplay : boolean;
  animationState = "right";
  constructor(private router: Router) {
    //set screenWidth on page load
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if(this.screenWidth > 950){
      this.ToolbarDisplay = true;
      this.SideNaveDisplay = false;
    }else{
      this.ToolbarDisplay = false;
      this.SideNaveDisplay = true;
    }
  }
  
  @HostListener('window:scroll', ['$event']) 
  doSomething() {
    if(window.pageYOffset > 50){
      this.ChangeBackground = true;
      this.BachinaLabs = "assets/images/BachinaLabs.png";
    }else{
      this.ChangeBackground = false;
      this.BachinaLabs = "assets/images/BachinaLabsWhite.png";
    }
  }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  @HostListener('window:resize', ['$event']) 
  onResize(event : any) {
    this.screenWidth = event.target.innerWidth;
    if(this.screenWidth > 950){
      this.ToolbarDisplay = true;
      this.SideNaveDisplay = false;
    }else{
      this.ToolbarDisplay = false;
      this.SideNaveDisplay = true;
    }
  }
}