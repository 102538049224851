<div *ngIf="screenWidth > 992" class="row bg_turnyourideas_large" style="margin: 0px;padding: 0px;">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-5 verticalalign_parent">
        <div class="verticalalign_child">
            <label style="width: 100%;font-size: 40px; font-weight: bold; color: white;">Turn your ideas</label>
            <label style="width: 100%;font-size: 40px; font-weight: bold; color: white;margin-top: 30px;">into Projects.</label>
            <label style="width: 80%;font-size: 17px; color: white;margin-top: 40px;">We Arrange Many Events & Meetup Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuinWe Arrange Many Events & Meetup Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin</label>
        </div>
    </div>
    <div class="col-lg-5 verticalalign_parent">
        <div class="verticalalign_child">
            <mat-form-field class="mat-form-wrap" appearance="outline">
                <mat-label style="font-size: 15px;color: white;padding-left: 15px;">Enter Mobile Number</mat-label>
                <input style="font-weight: bold" matInput>
            </mat-form-field>
            <button mat-raised-button class="scheduleacall">Schedule a call</button>
        </div>
    </div>
</div>
<div *ngIf="screenWidth < 993" class="row bg_turnyourideas_small" style="margin: 0px;padding: 0px;">
    <div class="col-lg-12 verticalalign_parent">
        <div class="verticalalign_child">
            <label style="width: 100%;font-size: 40px; font-weight: bold; color: white;">Turn your ideas</label>
            <label style="width: 100%;font-size: 40px; font-weight: bold; color: white;margin-top: 30px;">into Projects.</label>
            <label style="width: 80%;font-size: 17px; color: white;margin-top: 40px;">We Arrange Many Events & Meetup Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuinWe Arrange Many Events & Meetup Sed ut perspiciatis unde omnis iste natus error sit voluptatem accuin</label>
            <mat-form-field class="mat-form-wrap" appearance="outline">
                <mat-label style="font-size: 15px;color: white;padding-left: 15px;">Enter Mobile Number</mat-label>
                <input style="font-weight: bold" matInput>
            </mat-form-field>
            <button mat-raised-button class="scheduleacall">Schedule a call</button>
        </div>
    </div>
</div>