<div class="row" style="margin: 0px;padding : 0px;background-color: #161849;">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-10">
        <label style="margin-top: 150px;width: 100%;font-size: 35px;font-weight: bold;color: white;letter-spacing: 1px;">Latest Stories</label>
        <div class="row" style="margin-top: 15px;margin-bottom: 90px;">
            <div class="col-lg-3" style="margin-top: 20px;" *ngFor="let latest_stories of LatestStories;let i = index">
                <div class="mat-elevation-z3" style="background-color: #1C2256;padding: 50px;">
                    <label style="font-size: 20px;font-weight: bold;color: white;letter-spacing: 1px;">{{latest_stories.Header}}</label>
                    <label style="font-size: 18px;margin-top: 20px;color: #9196B4;letter-spacing: 1px;">{{latest_stories.Content}}</label>
                </div>
            </div>
        </div>
    </div>
</div>