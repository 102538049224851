import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  // { path:'',redirectTo: 'Home', pathMatch: 'full'},
  { path:'',component:HomeComponent},
  { path:'About',component:AboutComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation : 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }