import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ideas-into-projects',
  templateUrl: './ideas-into-projects.component.html',
  styleUrls: ['./ideas-into-projects.component.css']
})
export class IdeasIntoProjectsComponent implements OnInit {
  screenHeight;screenWidth;
  constructor (private router: Router) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    window.onresize = () => {
      //set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    };
  }
  ngOnInit(): void {
  }
}