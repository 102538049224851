import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-provide-training',
  templateUrl: './we-provide-training.component.html',
  styleUrls: ['./we-provide-training.component.css']
})
export class WeProvideTrainingComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}