<div class="imageslider">
    <img [src]="imageSrc" style="width: 100%;height: 100%;"/><!-- [@slideInOut]="animationState" -->
</div>
<div class="imageslider_overlapping_wrap verticalalign_parent_image"><!-- [ngStyle]="{'top': ChangeBackground == true ? '0px' : '60px'}"  -->
    <div class="verticalalign_child_image">
        <div class="row" style="margin: 0px;padding: 0px;">
            <div class="col-2" style="margin: 0px;padding : 0px;text-align: center;">
                <div class="verticalalign_parent">
                    <mat-icon class="verticalalign_child" style="color: white;cursor: pointer;" (click)="LeftArrowClick()">keyboard_arrow_left</mat-icon>
                </div>
            </div>
            <div class="col-8" style="margin: 0px;padding : 0px;">
                <label class="slider_header_wrap">Train Yourself From</label>
                <label class="slider_header_wrap_second">Industry Experts</label>
                <label class="slider_content">A modern and unique style to make your Educational platform standout. to make your Educational platform standout.</label>
                <div style="width: 100%;">
                    <div class="book_a_call">
                        <label style="width : 215px;z-index: -1;">Book a call</label>
                        <label style="width : 25px;"><mat-icon style="color: #E4440E;font-size: 25px;position: absolute;top: 83.5%;">arrow_forward</mat-icon></label>
                    </div>
                </div>
            </div>
            <div class="col-2" style="text-align: center;">
                <div class="verticalalign_parent">
                    <mat-icon class="verticalalign_child" style="color: white;cursor: pointer;" (click)="RightArrowClick()">keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>