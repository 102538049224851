import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-get-trained',
  templateUrl: './get-trained.component.html',
  styleUrls: ['./get-trained.component.css']
})
export class GetTrainedComponent implements OnInit {
  screenHeight;screenWidth;
  constructor (private router: Router) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    // window.onresize = () => {
    //   //set screenWidth on screen size change
    //   this.screenWidth = window.innerWidth;
    //   this.screenHeight = window.innerHeight;
    // };
  }
  ngOnInit(): void {
  }
  @HostListener('window:resize', ['$event']) 
  onResize(event : any) {
    this.screenWidth = event.target.innerWidth;
    // if(this.screenWidth > 600){
    //   this.TabVersion = true;
    //   this.mobileVersion = false;
    // }else{
    //   this.TabVersion = false;
    //   this.mobileVersion = true;
    // }
  }
}