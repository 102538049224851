import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscribe-for-stories',
  templateUrl: './subscribe-for-stories.component.html',
  styleUrls: ['./subscribe-for-stories.component.css']
})
export class SubscribeForStoriesComponent implements OnInit {
  EmailImg : string = "assets/images/NewArticle.png";
  mobileVersion : boolean;
  TabVersion : boolean;
  screenHeight;screenWidth;
  constructor(private router: Router) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if(this.screenWidth > 600){
      this.TabVersion = true;
      this.mobileVersion = false;
    }else{
      this.TabVersion = false;
      this.mobileVersion = true;
    }
  }

  ngOnInit(): void {
  }
  @HostListener('window:resize', ['$event']) 
  onResize(event : any) {
    this.screenWidth = event.target.innerWidth;
    if(this.screenWidth > 600){
      this.TabVersion = true;
      this.mobileVersion = false;
    }else{
      this.TabVersion = false;
      this.mobileVersion = true;
    }
  }
}
