import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  constructor() { }

  imageSrc : string = "assets/images/background.png";
  ImagesList : string[] = ["assets/images/background.png","assets/images/CodingPlatforms.jpg","assets/images/TrainYourselfFromIndustryExperts.png"];
  SelectedImage : number = 0;
  ngOnInit(): void {
  }
  LeftArrowClick(){
    if(this.SelectedImage == 0){
    }else{
      this.SelectedImage--;
      this.imageSrc = this.ImagesList[this.SelectedImage];
      // this.animationState = "left";
    }
  }
  RightArrowClick(){
    if(this.SelectedImage == 2){
    }else{
      this.SelectedImage++;
      this.imageSrc = this.ImagesList[this.SelectedImage];
      // this.animationState = "right";
    }
  }
}
