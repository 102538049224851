<div class="row" style="margin: 0px;padding : 0px">
    <app-banner style="width: 100%;"></app-banner>
    <div class="new_article_section">
        <app-subscribe-for-stories></app-subscribe-for-stories>
    </div>
</div>
<app-latest-stories></app-latest-stories>
<app-we-provide-training></app-we-provide-training>
<app-get-trained></app-get-trained>
<app-ideas-into-projects></app-ideas-into-projects>
<app-follow-us></app-follow-us>
<app-main-footer></app-main-footer>