import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { BannerComponent } from './home/banner/banner.component';
import { GetTrainedComponent } from './home/get-trained/get-trained.component';
import { FollowUsComponent } from './home/follow-us/follow-us.component';
import { LatestStoriesComponent } from './home/latest-stories/latest-stories.component';
import { IdeasIntoProjectsComponent } from './home/ideas-into-projects/ideas-into-projects.component';
import { MainHeaderComponent } from './shared/main-header/main-header.component';
import { MainFooterComponent } from './shared/main-footer/main-footer.component';
import { SubscribeForStoriesComponent } from './home/subscribe-for-stories/subscribe-for-stories.component';
import { WeProvideTrainingComponent } from './home/we-provide-training/we-provide-training.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    GetTrainedComponent,
    FollowUsComponent,
    LatestStoriesComponent,
    IdeasIntoProjectsComponent,
    MainHeaderComponent,
    MainFooterComponent,
    SubscribeForStoriesComponent,
    WeProvideTrainingComponent,
    HomeComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,MaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
